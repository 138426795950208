import React, { useState, useEffect } from 'react';
import axios from 'axios';
import documentIcon from '../Images/pdf.png'; 
import Slider from '../Components/SliderPublications';


const Publications = () => {
 
  const [publicationsTitles, setPublicationsTitles] = useState([]); // Note the plural variable name to indicate an array
  const [publications, setPublications] = useState([]);
  const [images, setImages] = useState([]); 


  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };
  
    fetchSliderDetails();
  }, []);


  useEffect(() => {
    const fetchPublicationsTitles = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/publications/list'); // Adjusted endpoint for clarity
        if (response.data.success && Array.isArray(response.data.data)) {
          setPublicationsTitles(response.data.data); // Assuming this is an array of titles
        } else {
          console.error("Unexpected data format for publications titles:", response.data);
        }
      } catch (error) {
        console.error('Error fetching publications titles:', error);
      }
    };
  
    fetchPublicationsTitles();
  }, []);
  
  

    

  useEffect(() => {
    // Fetch publications data from the backend
    const fetchPublications = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/card/list'); // Adjust the endpoint as needed
        if (response.data.success) {
          setPublications(response.data.data); // Update state with fetched data
        }
      } catch (error) {
        console.error('Error fetching publications:', error);
      }
    };

    fetchPublications();
  }, []);
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
  
  <div className="container bg-[#777777] items-center   mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20 " style={{ backgroundColor: 'white' }}>
   
  <Slider Publications="Publications" />
   
    {/* {publicationsTitles.map((publication, index) => (
  <h1 key={index} className="text-2xl md:text-4xl font-bold text-center pt-20 mb-8 text-[#972a36]" style={{ fontFamily: 'Arial' }}>
   
  </h1>
))} */}

      <div className="w-full flex justify-center mb-10 pb-10 mt-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-4 xl:px-32">
        {publications.map((pub, index) => (
  <div key={index} className="bg-white shadow-xl rounded p-4 m-4 flex flex-col justify-between h-full">
    <div className="flex flex-col items-center flex-grow">
      <h3 className="text-md font-semibold mb-2 text-center mb-5 mt-5" style={{ fontFamily: 'Arial' }}>{pub.title}</h3>
      <p className="text-sm md:text-xs xl:text-sm text-gray-800 mb-4 flex-grow" style={{ fontFamily: 'Arial' }}>{pub.description}</p>
    </div>
    <div className="flex justify-center items-center w-full gap-5 mb-5">
      <a
        href={`https://demo1.we-demo.xyz/${pub.filepdf}`}
        className="hover:text-red-800 transition duration-300 mt-5"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#840626', fontFamily: 'Arial' }}
      >
        View Document →
      </a>
      <div className="flex justify-end">
        <img src={documentIcon} alt="Document" className="mt-4 w-10 h-10" />
      </div>
    </div>
  </div>
))}



        </div>
        
      </div>
      <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
    </div>
    
    </>
  );
};

export default Publications;
