import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from '../Components/SliderNews';

const NewsDetail = () => {
  const location = useLocation();
  const { imageSrc, title, description } = location.state;
  const [dynamicHeight, setDynamicHeight] = useState('350px');

  useEffect(() => {
    const updateHeight = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) {
        setDynamicHeight('250px');
      } else if (screenWidth >= 640 && screenWidth < 768) {
        setDynamicHeight('300px');
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setDynamicHeight('350px');
      } else if (screenWidth >= 1024 && screenWidth < 1280) {
        setDynamicHeight('400px');
      } else {
        setDynamicHeight('405px');
      }
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-24" style={{ backgroundColor: 'white' }}>
        <Slider News="NEWS"/>
        <div className="mx-auto px-4 sm:px-6 lg:px-10 py-10 mt-0 flex flex-col gap-10 lg:gap-20">
          <div className="flex flex-col items-start justify-start mt-12 lg:mt-14 text-[#972a36] ml-4">
            <h1 className="text-lg sm:text-xl md:text-2xl font-bold mb-0 text-start" style={{ fontFamily: 'Arial' }}>{title}</h1>
            <span className="bg-[#777777] h-0.5 w-16 mt-2 mb-14" />
            <div className="flex flex-col lg:flex-row items-start gap-4 lg:gap-20 mb-14">
              <div className="flex-grow">
                <div
                  className="text-black text-start m-0"
                  style={{ textAlign: 'justify', textJustify: 'inter-word', fontFamily: 'Arial' }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              <div className="flex-none">
                <img src={`https://demo1.we-demo.xyz/${imageSrc}`} alt={title} className="w-full lg:w-96  object-contain rounded-xl mt-4 lg:mt-0" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
      </div>
    </>
  );
};

export default NewsDetail;
