import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AboutSection = () => {
  const [aboutSections, setAboutSections] = useState([]);

  useEffect(() => {
    const fetchAboutSections = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/about/list');
        if (response.data && response.data.success) {
          const allSections = response.data.data;
          const lastThreeSections = allSections.length > 3 ? allSections.slice(-3) : allSections;
          setAboutSections(lastThreeSections);
        } else {
          console.error('No about sections found');
        }
      } catch (error) {
        console.error('Error fetching about sections:', error);
      }
    };

    fetchAboutSections();
  }, []);

  return (
    <div className="py-16 px-10 md:px-32 pl-16 md:pl-24 xl:pl-36 grid grid-cols-1 md:grid-cols-1 xl:grid-cols-3 gap-24 mx-auto xl:max-w-7xl pt-20" style={{ backgroundColor: 'white' }}>
      {aboutSections.map((section, index) => (
        <div key={section.id} className="flex flex-col  relative">
          <h3 className="text-2xl font-bold mb-4 text-[#972a36]" style={{ fontFamily: 'Arial' }}>{section.title}</h3>
          <p className="text-md text-gray-800 mb-8 ">{section.description}</p>
          <div className="flex justify-center mt-auto">
            <a
              href={
                index === 0 ? "/about-us/profile" :
                index === 1 ? "/about-us/our-founder" :
                "/Lawyers"
              }
              rel="noopener noreferrer"
            >
              <button className="py-2 px-4 bg-[#972a36] text-white rounded w-36">
                {index === 0 || index === 1 ? "Read More" : "Meet our Team"}
              </button>
            </a>
          </div>
          {index !== aboutSections.length - 1 && (
            <div className="xl:absolute xl:top-2 xl:-right-10 xl:h-full xl:w-0.5 xl:mt-0 xl:bg-[#777777]"></div>
          )}
        </div>
      ))}
    </div>
  );
}

export default AboutSection;
