import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Slider from '../Components/SliderLawyers';



const renderParagraph = (para) => {
  // Remove the surrounding <ul></ul> tags
  const cleanedContent = para.replace(/<\/?ul[^>]*>/g, '');
  // Split the cleaned content into individual items
  const items = cleanedContent.split('</li>').map((item, index) => {
    // Remove opening <li> tag and any extra spaces
    const cleanedItem = item.replace('<li>', '').trim();
    // Render each item as a div with bullet point if not empty
    return cleanedItem ? <div key={index} className="mb-2">• {cleanedItem}</div> : null;
  });

  // Return the list of items wrapped in a div
  return <div className="mb-4 text-left ml-5 mt-5">{items}</div>;
};

const styles = `
  @keyframes scaleUp {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.05);
    }
  }

  .hover-card {
    transition: transform 0.3s ease;
  }

  .hover-card:hover {
    animation: scaleUp 0.3s ease forwards;
  }
`;

const enhanceHtml = (html) => {
  return html
    .replace(/<h1>/g, '<h1 class="text-4xl font-bold">')
    .replace(/<li>/g, '<li class="list-disc list-inside">');
    
};

const Card = ({ image, headtitle, title, paragraph, onClick }) => (
  <div 
    className="border rounded-lg overflow-hidden relative mb-8 w-full cursor-pointer flex"
    onClick={onClick}
  >
    <div className="flex-shrink-0">
      <img src={image} alt={headtitle} className="w-40 h-46 object-cover m-4" />
    </div>
    <div className="p-4 flex flex-col justify-between flex-grow">
      <h1 className="font-bold text-lg mb-1" style={{ fontFamily: 'Arial' }}>{headtitle}</h1>
      <h2 className="text-sm mb-1" style={{ fontFamily: 'Arial' }}>{title}</h2>
      <p className="text-sm mb-1" dangerouslySetInnerHTML={{ __html: enhanceHtml(paragraph) }}></p>
      <div className="text-right">
        <button className="text-[#972a36] text-sm">See my full profile</button>
      </div>
    </div>
  </div>
);

const OurTeam = () => {
  const [partnersData, setPartnersData] = useState([]);
  const [ofCounselData, setOfCounselData] = useState([]);
  const [associatesData, setAssociatesData] = useState([]);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTexts = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/SliderLawyer/listTexts');
        setTexts(response.data.data || []);
      } catch (error) {
        console.error('Failed to fetch texts', error);
      }
    };
  
    fetchTexts();
  }, []);
  

  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };
  
    fetchSliderDetails();
  }, []);

  useEffect(() => {
    const fetchPartnersData = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/ourteampartners/list');
        setPartnersData(response.data.data);
      } catch (error) {
        console.error("Failed to fetch partners data:", error);
      }
    };

    fetchPartnersData();
  }, []);


  const allData = [...partnersData, ...ofCounselData, ...associatesData];

  const handleCardClick = (item) => {
    navigate('/Lawyers-details', { state: { image: `https://demo1.we-demo.xyz/${item.image}`, headtitle: item.headtitle, title: item.title, paragraph: item.paragraph, content: item.content } });
  };



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20" style={{ backgroundColor: 'white' }}>
      <Slider Lawyers="Partners"/>
     

      <style>{styles}</style>
      <div className="w-full mt-20">
      {texts.map((text, index) => (
        <h2 className="text-3xl font-semibold mb-2 text-[#972a36] ml-7" key={index} dangerouslySetInnerHTML={{ __html: text.text }}></h2>
))}
 <div className="bg-[#777777] h-0.5 w-16  mt-2 mb-6 ml-7" ></div>
 </div>
      <div className="w-full mt-10">
    
 


                     
        {allData.map((item, index) => (
          <div key={index} className="w-full">
            <Card 
              image={`https://demo1.we-demo.xyz/${item.image}`}
              headtitle={item.headtitle}
              title={item.title}
              paragraph={item.paragraph} // Pass item.paragraph here
              contact={item.contact}
              phone={item.phone}
              email={item.email}
              onClick={() => handleCardClick(item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default OurTeam;
