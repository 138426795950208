import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from '../Components/SliderOurFounder';

const OurFounder = () => {
  const [founders, setFounders] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };

    fetchSliderDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchFounderDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/founder/list');
        if (response.data.success && response.data.data.length > 0) {
          setFounders(response.data.data); // Set all founder records
        }
      } catch (error) {
        console.error('Error fetching founder details:', error);
      }
    };

    fetchFounderDetails();
  }, []);

  if (!founders.length) {
    return <div>Loading...</div>;
  }

  const enhanceHtml = (html) => {
    return html
      .replace(/<h1>/g, '<h1 class="text-4xl ">')
      .replace(/<li>/g, '<li class="list-disc list-inside">');
      
  };

  return (
    <div className="container bg-white items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20">
      <Slider headtitle={founders[0]?.headtitle} />
      
      {/* Display all founder records */}
      {founders.map((founder, index) => (
        <div key={index} className="flex flex-col items-start mb-10 px-0 md:px-10 xl:px-24 mt-20">
          <div className="text-start px-4 md:px-10 xl:px-0">

            <div className="text-base md:text-lg mb-4 text-black" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }} dangerouslySetInnerHTML={{ __html: enhanceHtml(founder.titleone) }}></div>
          </div>
        </div>
      ))}

      <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
    </div>
  );
};

export default OurFounder;
