import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import axios from 'axios';
import Slider from '../Components/SliderProfile';

const Profile = () => {
  const [whatTheySayTitle, setWhatTheySayTitle] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [images, setImages] = useState([]); 
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState({
    title: '',
    paragraph: '',
    contentone: '',
    contenttwo: '',
    contentthree: '',
    contentfour: '',
    imageone: '',
    imagetwo: '',
    imagethree: '',
    imagefour: ''
  });

  const [backgroundDetails, setBackgroundDetails] = useState({
    image: '',
    title: '',
    paragraph: '',
    content: ''
  });

  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };
  
    fetchSliderDetails();
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/profile/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          setProfiles(response.data.data); // Set the data as an array of profiles
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
  
    fetchProfile();
  }, []);


  useEffect(() => {
    const fetchBackgroundDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/backgroundpropile/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          setBackgroundDetails(response.data.data[0]);
        }
      } catch (error) {
        console.error('Error fetching background details:', error);
      }
    };

    fetchBackgroundDetails();
  }, []);

  useEffect(() => {
    const fetchWhatTheySayTitle = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/whattheysay/list');
        if (response.data.success && response.data.data.length > 0) {
          setWhatTheySayTitle(response.data.data[0].title);
        }
      } catch (error) {
        console.error('Error fetching What They Say title:', error);
      }
    };

    fetchWhatTheySayTitle();
  }, []);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/textsay/list');
        if (response.data.success) {
          setTestimonials(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  const nextSlide = () => {
    setCurrentSlide(prev => (prev + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentSlide(prev => (prev - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Change slides every 5 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [testimonials.length]); // Recreate interval only when number of testimonials changes

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const enhanceHtml = (html) => {
    return html
      .replace(/<h1>/g, '<h1 class="text-4xl font-bold">')
      .replace(/<li>/g, '<li class="list-disc list-inside">');
      
  };

  return (
    <>
      <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20 " style={{ backgroundColor: 'white' }}>
        <Slider profileTitle="Profile" />
        {/* <div className="container mx-auto px-5 md:px-10 lg:px-24 flex flex-col justify-start items-start mb-10 mt-20 ">
          <h2 className="text-2xl font-semibold text-[#972a36]" style={{ fontFamily: 'Arial' }}>
            {profile.title}
          </h2>
          <span className="bg-[#777777] h-0.5 w-16 mt-2" />
        </div> */}
{profiles.map((profile, index) => (
  <div key={index} className="flex flex-col md:flex-row justify-between items-start md:items-center px-5 md:px-10 lg:px-24 mb-20 mt-20">
    {index % 2 === 0 ? (
      <>
        {profile.paragraph && profile.imageone ? (
          <>
            <div className="md:w-1/2">
              <p className="text-base text-gray-800" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }} dangerouslySetInnerHTML={{ __html: enhanceHtml(profile.paragraph) }}></p>
            </div>
            <div className="md:w-1/2 flex justify-end">
              <img src={`https://demo1.we-demo.xyz/${profile.imageone}`} alt={backgroundDetails.title} className="w-full md:w-3/4 lg:w-2/3 object-cover rounded-lg shadow-lg mt-10 md:mt-0" />
            </div>
          </>
        ) : profile.paragraph ? (
          <div className="w-full">
            <p className="text-base text-gray-800" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }} dangerouslySetInnerHTML={{ __html: enhanceHtml(profile.paragraph) }}></p>
          </div>
        ) : (
          <div className="w-full flex justify-end">
            <img src={`https://demo1.we-demo.xyz/${profile.imageone}`} alt={backgroundDetails.title} className="w-full md:w-3/4 lg:w-2/3 object-cover rounded-lg shadow-lg mt-10 md:mt-0" />
          </div>
        )}
      </>
    ) : (
      <>
        {profile.paragraph && profile.imageone ? (
          <>
            <div className="md:w-1/2 flex justify-start">
              <img src={`https://demo1.we-demo.xyz/${profile.imageone}`} alt={backgroundDetails.title} className="w-full md:w-3/4 lg:w-2/3 object-cover rounded-lg shadow-lg mb-10 md:mb-0" />
            </div>
            <div className="md:w-1/2">
              <p className="text-base text-gray-800" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }} dangerouslySetInnerHTML={{ __html: enhanceHtml(profile.paragraph) }}></p>
            </div>
          </>
        ) : profile.paragraph ? (
          <div className="w-full">
            <p className="text-base text-gray-800" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }} dangerouslySetInnerHTML={{ __html: enhanceHtml(profile.paragraph) }}></p>
          </div>
        ) : (
          <div className="w-full flex justify-start">
            <img src={`https://demo1.we-demo.xyz/${profile.imageone}`} alt={backgroundDetails.title} className="w-full md:w-3/4 lg:w-2/3 object-cover rounded-lg shadow-lg mb-10 md:mb-0" />
          </div>
        )}
      </>
    )}
  </div>
))}



        <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
        {/* <div className="container mx-auto mb-20"> */}
          {/* <div className="flex flex-col md:flex-row justify-between items-start md:items-center px-5 md:px-10 lg:px-24 mt-20 mb-20">
            <div className="md:w-1/2 flex justify-start">
              <img src={`https://demo1.we-demo.xyz/${profile.imagetwo}`} alt={backgroundDetails.title} className="w-full md:w-3/4 lg:w-2/3 object-cover rounded-lg shadow-lg mb-10 md:mb-0" />
            </div>
            <div className="md:w-1/2">
              <p className="text-base text-gray-800" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }}>{profile.contentone}</p>
            </div>
          </div> */}
        
          {/* <div className="flex flex-col md:flex-row justify-between items-start md:items-center px-5 md:px-10 lg:px-24 mt-20 mb-20">
            <div className="md:w-1/2">
              <p className="text-base text-gray-800 mt-4" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }}>{profile.contenttwo}</p>
            </div>
            <div className="md:w-1/2 flex justify-end">
              <img src={`https://demo1.we-demo.xyz/${profile.imagethree}`} alt={backgroundDetails.title} className="w-full md:w-3/4 lg:w-2/3 object-cover rounded-lg shadow-lg mt-10 md:mt-0" />
            </div>
          </div> */}
          {/* <div className="flex flex-col justify-start items-start mb-10 px-5 md:px-10 lg:px-24">
            <h2 className="text-2xl font-semibold text-[#972a36]" style={{ fontFamily: 'Arial' }}>
              Our story
            </h2>
            <span className="bg-[#777777] h-0.5 w-16 mt-2" />
          </div> */}
          {/* <p className="text-base text-gray-800 mt-4 px-5 md:px-10 lg:px-24" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }}>{profile.contentthree}</p>
          <p className="text-base text-gray-800 mt-4 px-5 md:px-10 lg:px-24" style={{ fontFamily: 'Arial', textAlign: 'justify', textJustify: 'inter-word' }}>{profile.contentfour}</p> */}
        {/* </div> */}
        {/* <div className="w-full mt-10 h-64 md:h-96 px-5 md:px-10 lg:px-24 mb-20">
          <img 
            src={`https://demo1.we-demo.xyz/${profile.imagefour}`} 
            alt="Background" 
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        </div> */}
        <h1 className="text-2xl md:text-4xl font-bold text-center text-[#972a36] mt-10" style={{ fontFamily: 'Arial' }}>What they say</h1>
        <p className="text-base text-gray-800 mt-8 text-center px-5 lg:px-60" style={{ fontFamily: 'Arial' }}>Ali Sharif Zu’bi Advocates & Legal Consultants has a renowned reputation both in Jordan and internationally. Praise for our team includes the following: </p>
        <div className="flex justify-center items-center ">
          <div className="relative w-full md:w-2/3">
            <button onClick={prevSlide} className="absolute left-0 md:left-0 lg:left-4 xl:left-0 text-lg md:text-2xl lg:text-xl xl:text-3xl z-50" style={{ top: '50%', transform: 'translateY(-50%)', color: '#ffffff', textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)' }}>
              &#10094;
            </button>
            <Carousel
              selectedItem={currentSlide}
              showArrows={false}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={5000}
              showIndicators={false}
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="p-4 md:p-6 mb-14">
                  <p className="text-xs md:text-sm text-gray-800 mt-10 md:mt-14" style={{ fontFamily: 'Arial' }}>"{testimonial.description}"</p>
                  <p className="text-xs md:text-sm text-center text-base text-gray-800 mt-4" style={{ fontFamily: 'Arial' }}>{testimonial.title}</p>
                </div>
              ))}
            </Carousel>
            <button onClick={nextSlide} className="absolute right-0 md:right-0 lg:right-4 xl:right-0 text-lg md:text-2xl lg:text-xl xl:text-3xl" style={{ top: '50%', transform: 'translateY(-50%)', color: '#ffffff', textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)' }}>
              &#10095;
            </button>
          </div>
        </div>
        <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
      </div>
    </>
  );
};

export default Profile;
