import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Slider from '../Components/SliderAreasOfPractice';


const PracticeDetail = () => {
  const location = useLocation();
  const { detail } = location.state || {}; // Destructure detail from location.state

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  if (!detail) {
    return <div>Loading...</div>;
  }
  const titleWords = detail.title.split(' ');
  const firstPart = titleWords.slice(0, Math.ceil(titleWords.length / 2)).join(' ');
  const secondPart = titleWords.slice(Math.ceil(titleWords.length / 2)).join(' ');

  const enhanceHtml = (html) => {
    return html
      .replace(/<h1>/g, '<h1 class="text-4xl font-bold">')
      .replace(/<li>/g, '<li class="list-disc list-inside">');
      
  };
  return (
    <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20" style={{ backgroundColor: 'white' }}>
       <Slider AreasOfPractice="Fields of Practice"/>
        

        <div className="mx-auto px-4 sm:px-6 lg:px-10 py-10 mt-0 flex flex-col gap-10 lg:gap-20">
  <div className="flex flex-col items-start justify-start mt-12 lg:mt-14 text-[#972a36] ml-4">
    <h3 className="text-lg sm:text-xl md:text-2xl font-bold mb-0 text-start" style={{ fontFamily: 'Arial' }}>
      {firstPart}
    </h3>
    <h3 className="text-lg sm:text-xl md:text-2xl font-bold mb-0 text-start" style={{ fontFamily: 'Arial' }}>
      {secondPart}
    </h3>
    <span className="bg-[#777777] h-0.5 w-16 mt-2 mb-14" />
    <div className="flex flex-col lg:flex-row items-start gap-4 lg:gap-20 mb-14">
      <div className="flex-grow">
      <div className="text-black text-start m-0" style={{ textAlign: 'justify', textJustify: 'inter-word', fontFamily: 'Arial' }} dangerouslySetInnerHTML={{ __html: enhanceHtml(detail.paragraph) }} />

      </div>
      <div className="flex-none">
        <img src={`https://demo1.we-demo.xyz/${detail.image}`} alt={detail.title} className="w-full lg:w-96 object-contain rounded-xl mt-4 lg:mt-0" />
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default PracticeDetail;
