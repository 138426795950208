import React, { useState, useEffect } from 'react';
import NavBar from '../Components/NavBar';
import Slider from '../Components/Slider';
import About from '../Components/About';
import Card from '../Components/Card';
import Partners from '../Components/Partners';
import Footer from '../Components/Footer';

const Home = () => {
    const [isXlScreen, setIsXlScreen] = useState(window.innerWidth >= 1200); // 1200px is a common breakpoint for XL screens

    useEffect(() => {
        const handleResize = () => {
            setIsXlScreen(window.innerWidth >= 1200);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div 
        // style={{
        //     backgroundColor: '#f5f5f5',
        //     border: isXlScreen ? '3px solid #7b7b7b' : 'none', // Apply border only on XL screens
        // }}
        >
            {/* <NavBar /> */}
            <Slider />
            <About />
            <Card />
            <Partners />
            {/* <Footer /> */}
        </div>
    );
}

export default Home;
