import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import menuIcon from '../Images/more.png';  
import arrowBlack from '../Images/arrow_b.png'; 
import arrowWhite from '../Images/arrow_w.png';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);  
  const [navbarItems, setNavbarItems] = useState([]);
  const [aboutNavbarItems, setAboutNavbarItems] = useState([]);
  const [logoDetails, setLogoDetails] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [showMore, setShowMore] = useState(false);
  const [isOurTeamDropdownOpen, setIsOurTeamDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  
  useEffect(() => {
    const handleScroll = () => {
      // Set state based on scroll position
      setIsScrolled(window.scrollY > 0);
    };

    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener when component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Define the style for the font family
  const fontFamilyStyle = { fontFamily: 'Arial' };

  useEffect(() => {
    // Fetch navbar items from the server
    const fetchNavbarItems = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/navbar/list'); // Adjust the API endpoint as needed
        setNavbarItems(response.data.data); // Assuming the response has a 'data' field containing the navbar items
      } catch (error) {
        console.error('Error fetching navbar items:', error);
      }
    };

    fetchNavbarItems();
  }, []);



  useEffect(() => {
    const fetchAboutNavbarItems = async () => {
      try {
        // Adjust the API endpoint as needed
        const response = await axios.get('https://demo1.we-demo.xyz/aboutnavbar/list'); 
        setAboutNavbarItems(response.data.data); // Update this line based on your actual API response structure
      } catch (error) {
        console.error('Error fetching about navbar items:', error);
      }
    };

    fetchAboutNavbarItems();
  }, []);


  useEffect(() => {
    // Fetch logo details from the server
    const fetchLogoDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/logo/list'); // Adjust the API endpoint as needed
        const logoData = response.data.data[0]; // Assuming the response has a 'data' field containing the logo details and we're interested in the first one
        setLogoDetails(logoData.logo); // Store the logo URL
      } catch (error) {
        console.error('Error fetching logo details:', error);
      }
    };

    fetchLogoDetails();
  }, []);

  const moreDropdownClass = isOpen ? 
    'nav-link block text-sm md:text-sm font-sm transition duration-300 py-2 lg:py-0 lg:px-4 text-center border-b border-white cursor-pointer w-full' : 
    'hidden lg:block nav-link text-sm md:text-sm font-sm transition duration-300 lg:py-0 lg:px-4 lg:text-left text-[#777777] lg:border-r lg:border-black cursor-pointer';


  return (
    <div className={`flex justify-center items-center ${isScrolled ? 'fixed top-0 left-0 w-full z-50' : ''}`} style={{ backgroundColor: '#f5f5f5', transition: 'background-color 0.3s ease' }}>
            <style>
        {`
        .lg-animated-border::after {
          content: '';
          display: block;
          width: 0;
          height: 2px;
          background: #972a36;
          transition: width 0.3s ease;
        }

        .lg-animated-border:hover::after {
          width: 100%;
        }
        .animated-border::after {
          content: '';
          display: block;
          width: 0;
          height: 2px;
          background: #972a36;
          transition: width 0.3s ease;
        }
  
        .animated-border:hover::after {
          width: 100%;
        }
  
        .custom-hover-border:hover {
          border-color: #972a36;
        }
          .nav-link:hover, .dropdown-content a:hover {
            color: #972a36; /* Custom hover color */
          }

          /* Add hover functionality for desktop view */
          .dropdown:hover .dropdown-content, .dropdown:focus-within .dropdown-content {
            display: block; /* Show dropdown content on hover or focus within */
          }

          .dropdown-content a {
            color: '#777777'; /* Initial color for dropdown links */
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            transition: color 0.3s;
            
          }

          .dropdown-content {
            display: none; /* Initially hide dropdown content */
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            left: 0; 
            margin-top: 15px
            
          }

          .dropdown {
            position: relative;
            display: inline-block;
          }

          .dropdown::before {
            content: '';
            position: absolute;
            top: 100%; /* Aligns right at the bottom of the dropdown */
            left: 0;
            width: 100%;
            height: 15px; /* Same as the gap */
            background: transparent; /* Keeps it invisible */
          }
          .font-arial {
            font-family: 'Arial', sans-serif;
          }
        `}
      </style>
      <div className="flex flex-col lg:flex-row justify-between items-center py-2 relative z-30 mx-auto xl:max-w-7xl w-full px-5 md:px-10 xl:px-0" style={{ backgroundColor: '#f5f5f5' }}>
  <div className="flex justify-between items-center w-full lg:w-auto">
    {/* Logo */}
    <Link to="/" className="font-arial">
      {logoDetails && <img src={`https://demo1.we-demo.xyz/${logoDetails}`} alt="Logo" className="w-52 md:w-60 sm:w-40 xl:w-80 h-auto" />}
      {/* Add a fallback or placeholder image in case logoDetails is null */}
    </Link>

    {/* Hamburger Menu Icon */}
    <div className="lg:hidden">
      <button onClick={() => setIsOpen(!isOpen)} className="font-arial">
        <img src={menuIcon} alt="Menu" className="w-8 h-8" />
      </button>
    </div>
  </div>

  {/* Navigation Links */}
  <div className={`${isOpen ? 'flex' : 'hidden'} justify-between uppercase lg:flex flex-col mt-5 lg:mt-12 lg:flex-row items-center md:items-end gap-2 md:gap-2 sm:gap-4 pl-0 xl:pl-20 pr-0 xl:pr-0 justify-center md:justify-end w-full font-arial`}>
  {/* Dynamically generate navbar links */}
  {navbarItems.slice(0, 7).map((item) => {
    if (item.name === "About Us") {
      // About Us Dropdown
      return (
        <div key={item.id} className="dropdown w-full text-[#777777] lg:w-auto flex items-center" onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
          <Link to="/about-us/profile" className={`nav-link block text-sm md:text-xs font-semibold transition duration-300 py-2 lg:py-0 lg:px-2 text-center lg:text-left ${isOpen ? 'text-[#777777] border-b border-[#972a36]' : 'text-[#777777] hover:text-gray-800 animated-border lg:lg-animated-border'} cursor-pointer`}>
            {item.name}
            <img src={isOpen || isDropdownOpen ? arrowBlack : arrowBlack} alt="Arrow" className="inline ml-2 xl:ml-0 h-3 xl:h-0 w-3 xl:w-0" />
          </Link>
          {isDropdownOpen && (
            <div className="dropdown-content normal-case text-xs">
              {aboutNavbarItems.map((subItem) => (
                <Link key={subItem.id} to={subItem.link} className="font-arial">
                  {subItem.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      );
      } else if (item.name === "Our Team") {
        // Our Team Dropdown
        return (
          <div key={item.id}  className="dropdown w-full text-[#777777] lg:w-auto flex items-center" onMouseEnter={() => setIsOurTeamDropdownOpen(true)} onMouseLeave={() => setIsOurTeamDropdownOpen(false)}>
            <div className={`nav-link block text-sm md:text-xs font-semibold transition duration-300 py-2 lg:py-0 lg:px-2 text-center lg:text-left ${isOpen ? 'text-[#777777] border-b border-[#972a36]' : 'text-[#777777] hover:text-gray-800 animated-border lg:lg-animated-border'} cursor-pointer`}>
            <Link to="/Lawyers">
              {item.name}
              <img src={isOpen || isOurTeamDropdownOpen ? arrowBlack : arrowBlack} alt="Arrow" className="inline ml-2 xl:ml-0 h-3 xl:h-0 w-3 xl:w-0" />
           </Link>
            </div>
            {isOurTeamDropdownOpen && (
              <div className="dropdown-content normal-case text-xs">
                {/* Direct links for Department and Team */}
                <Link to="Lawyers" className="font-arial">Partners</Link>
                <Link to="Departments" className="font-arial">Of Counsel</Link>
                <Link to="Associates" className="font-arial">Associates</Link>
              </div>
            )}
          </div>
        );
      } else {
        // Normal Link Items
        return (
          <Link key={item.id} to={item.link} className={`nav-link block text-sm md:text-xs font-semibold transition duration-300 py-2 lg:py-0 lg:px-2 text-center lg:text-left w-full lg:w-auto ${isOpen ? 'text-[#777777] border-b border-[#972a36]' : 'text-[#777777] hover:text-gray-800 animated-border lg:lg-animated-border'} font-arial`}>
            {item.name}
          </Link>
        );
      }
    })}
    {/* More Dropdown */}
    {navbarItems.length > 7 && (
      <div className="dropdown w-full lg:w-auto" onMouseEnter={() => setShowMore(true)} onMouseLeave={() => setShowMore(false)}>
        <div className={`nav-link block text-sm md:text-xs font-semibold transition duration-300 py-2 lg:py-0 lg:px-2 text-center lg:text-left cursor-pointer ${isOpen ? 'text-[#777777] border-b border-[#972a36]' : 'text-[#777777] hover:text-gray-800 animated-border lg:lg-animated-border'}`}>
          More
          <img src={showMore || isOpen ? arrowBlack : arrowBlack} alt="Arrow" className="inline ml-2 h-3 w-3" />
        </div>
        {showMore && (
          <div className="dropdown-content">
            {/* Additional items */}
            {navbarItems.slice(7).map(item => (
              <Link key={item.id} to={item.link} className="block text-sm md:text-xs font-semibold transition duration-300 py-2 lg:py-0 lg:px-2 text-center lg:text-left text-[#777777] hover:text-gray-800 font-arial">
                {item.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    )}
  </div>
</div>


      {/* Overlay for Mobile Menu */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-20" onClick={() => setIsOpen(false)} style={fontFamilyStyle}></div>
      )}
    </div>
  );
};

export default NavBar;
