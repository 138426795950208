import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Slider from '../Components/SliderDetailedDepartments';

const DetailedDepartments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { image, headtitle, title, paragraph, content } = location.state || {};

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

   
  const renderParagraph = (para) => {
    // Remove the surrounding <ul></ul> tags
    const cleanedContent = para.replace(/<\/?ul[^>]*>/g, '');
    // Split the cleaned content into individual items
    const items = cleanedContent.split('</li>').map((item, index) => {
      // Remove opening <li> tag and any extra spaces
      const cleanedItem = item.replace('<li>', '').trim();
      // Render each item as a div with bullet point if not empty
      return cleanedItem ? <div key={index} className="mb-2">• {cleanedItem}</div> : null;
    });

    // Return the list of items wrapped in a div
    return <div className="mb-4 text-left ml-5 mt-5">{items}</div>;
  };

  if (!image) {
    return <div>Loading...</div>;
  }

  const enhanceHtml = (html) => {
    return html
      .replace(/<h1>/g, '<h1 class="text-4xl font-bold">')
      .replace(/<li>/g, '<li class="list-disc list-inside">');
      
  };

  return (
    <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20" style={{ backgroundColor: 'white' }}>
      <Slider Departments={`${headtitle} Profile `} />

      <div className="bg-white p-6 mt-20">
  <div className="flex flex-col md:flex-row items-center mb-6">
    <div className="md:w-1/3 w-full mb-4 md:mb-0">
      <img src={image} alt={headtitle} className="w-full h-auto object-cover rounded-lg shadow-md" />
    </div>
    <div className="md:w-2/3 w-full md:ml-6">
      <h1 className="font-bold text-xl md:text-3xl mt-4 mb-2 text-center md:text-left" style={{ fontFamily: 'Arial' }}>{headtitle}</h1>
      <h2 className="text-md md:text-xl mb-4 text-center md:text-left" style={{ fontFamily: 'Arial' }}>{title}</h2>
      <div className="text-center md:text-start">
  <div className="border-b-4 inline-block w-20 mb-4 mx-auto" style={{ borderColor: '#972a36'}}></div>
  {renderParagraph(paragraph)}
</div>

    </div>
  </div>
  <div className="border-t border-gray-300 pt-4">

  <p className="px-0 md:px-8" style={{ textAlign: 'justify', textJustify: 'inter-word', fontFamily: 'Arial' }} dangerouslySetInnerHTML={{ __html: enhanceHtml(content) }}></p>
  </div>
</div>

    </div>
  );
};

export default DetailedDepartments;
