import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from 'axios';
import Slider from '../Components/SliderWhatTheySay';

const WhatTheySay = () => {
  const [backgroundDetails, setBackgroundDetails] = useState([]);



  useEffect(() => {
    const fetchBackgroundDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/backgroundpropile/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          setBackgroundDetails(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching background details:', error);
      }
    };
  
    fetchBackgroundDetails();
  }, []);
  

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const enhanceHtml = (html) => {
    return html
      .replace(/<h1>/g, '<h1 class="text-4xl ">')
      .replace(/<li>/g, '<li class="list-disc list-inside">');
      
  };


  return (
    <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20 " style={{ backgroundColor: 'white' }}>
      <Slider whatTheySayTitle="Pro Bono" />
  
      <div className="w-full mt-9 mb-10 pb-10 ">
        <div className="w-full p-6 md:p-10 flex flex-col justify-center items-center">
          {backgroundDetails.map((detail, index) => (
            <div key={index} className="text-base md:text-lg mb-4 text-center px-12  text-black" style={{ fontFamily: 'Arial', textAlign: 'center',}} dangerouslySetInnerHTML={{ __html: enhanceHtml(detail.paragraph) }}></div>
          ))}
        </div>
      </div>
      <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
    </div>
  );
  
};

export default WhatTheySay;
