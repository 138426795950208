import React from 'react';
import Slider from '../Components/Slider';


const TermsAndConditions = () => {
  return (
    <>
    
    <div className="container bg-[#777777] items-center   mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-10 mb-20" style={{ backgroundColor: 'white' }}>
    <Slider />
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-14">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-6">
          <h1 className="text-4xl font-bold text-center mb-12 mt-5">Terms of Business</h1>
           
            <p className="mb-8">
            Our aim is to provide you with high quality legal services. To achieve this, it is important that our clients and we have a common understanding of the basis on which we provide our services. Such an understanding should facilitate our work and make it more efficient. In the course of our representation we shall need your full cooperation and that of others working for you. We will rely on you to furnish or to cause others to furnish us with the information and assistance we consider necessary to carry out the representation in a timely, effective and professional manner.
            </p>
            <h2 className="text-xl font-semibold mb-2">1. Our Services</h2>
            <p className="mb-4">
            1.1 The services we shall provide you with in relation to a particular matter will be agreed at the outset of the relevant matter and may be varied by agreement during the course of such matter.            </p>
           
            <p className="mb-4">
            1.2 The services we provide are for the benefit of the party to whom these terms are sent, and as specified in the accompanying letter. A person who is not a party to it has, unless otherwise agreed in writing, no rights to enforce any term of this retainer.            
            </p>

            <p className="mb-4">
            1.3 Where we are acting for clients jointly, we shall act upon the instructions of either client unless agreed otherwise in advance. If we receive conflicting or inconsistent instructions from the joint clients, we may, at our discretion, refrain from taking any action until the conflict or inconsistency is resolved to our satisfaction.
            </p>

            <p className="mb-4">
            1.4 Our services shall include advice on tax related issues to the extent this is requested at the outset, or agreed during the course of a matter, but not otherwise.
            </p>


            <h2 className="text-xl font-semibold mb-2">2. People Doing the Work</h2>
            <p className="mb-4">
            We shall notify you at the outset of the matter of the partner(s) and/or associate(s) of the Firm acting on your behalf. During the course of the matter, should a change to such individuals occur, we shall notify you of the same.
            </p>
           
            
            <h2 className="text-xl font-semibold mb-2">3. Basis of Our Charges</h2>
            <p className="mb-4">
            We shall agree separately on the basis of our charges for acting on your behalf in relation to any particular matter. We shall also agree whether an advance payment will be required. Our normal guideline hourly rates will apply in the absence of any other agreement. Our rates are reviewed and may be adjusted periodically. Additional charges, including expenses incurred, will also be billed. We take your initial instruction to us as your authority for us to incur, without further reference to you, reasonable expenses. Where expenses will be substantial we shall generally ask for a deposit on account before we incur any such liability, or arrange for the expenses to be paid by you directly.
            </p>
           

            <h2 className="text-xl font-semibold mb-2">4. Sales Tax</h2>
            <p className="mb-4">
            The Jordanian General Sales Tax Law imposes a 16% Sales Tax on legal services. This Sales Tax will be set out in our invoices to you. This Sales Tax is subject to increase and we shall notify you of any changes should this occur.
            </p>


            <h2 className="text-xl font-semibold mb-2">5. Payment of Our Bills</h2>
            <p className="mb-4">
            Unless we agree otherwise:
            </p>

            <p className="mb-4">
            5.1 We shall invoice you monthly. If you have any queries regarding any invoice, please raise them with the partner responsible for the matter as soon as possible. Objections must be raised within fifteen (15) days from the invoice date.
            </p>

            <p className="mb-4">
            5.2 We require that our invoices be paid no later than thirty (30) days after their respective date of issue. Where an element of an invoice is queried, those sections of the invoice not subject to query are to be paid within thirty (30) days.
            </p>

            <p className="mb-4">
            5.3 Where any amount owed to us remains outstanding more than thirty (30) days from the issue of the relevant invoice, we may:
            </p>

            <p className="mb-4">
            5.3.1 stop acting on your behalf; and
            </p>

            <p className="mb-4">
            5.3.2 retain documents and papers belonging to you, together with our own records;until your account is settled.
            </p>

            <p className="mb-4">
            5.4 Where we are holding money on your behalf, on account or otherwise, we may use such funds toward payment or part payment of your outstanding invoices, unless otherwise agreed. You will receive prompt notice of any such action.
            </p>


            <h2 className="text-xl font-semibold mb-2">6. Conflict of Interest and Confidential Information</h2>
            <p className="mb-4">
            6.1 We shall not represent another client on any matter on which we are acting on your behalf unless we have your consent for doing so or where we are involved in transactional work for you expressly on a non-exclusive basis. You agree, however, that we are otherwise free to represent any other client either generally or on any specific matter in which you may have an interest, even if there is, or may be, a conflict between your interests and those of the other client.
            </p>

            <p className="mb-4">
            6.2 The interests of another client of the Firm and your own may conflict in any number of circumstances. The effect of 6.1 is that we may act for another client on any issue or matter in which you might have an interest including, without limitation, (a) agreement to buy, sell or lease: mergers; joint ventures; acquisitions; financing; contract for services; insolvency, the protection of rights; representations to regulatory authorities; and (b) the resolution of any disputes, whether in any judicial forum, arbitration or otherwise. Except where we have expressly agreed with you otherwise, we may also act generally for another client who may be a market competitor of yours.
            </p>

            <p className="mb-4">
            6.3 We owe a duty of confidentially to all our clients and accordingly, subject to any overriding legal obligation on you or us:
            </p>

            <p className="mb-4">
            6.3.2 you agree that we are in no way obliged to disclose to you or use, for your purposes, any documents or information in respect of which we owe a duty of confidentiality to another client (or any other person).
            </p>

            <p className="mb-4">
            6.4 You agree that no documents held by this Firm, regardless of relevance, will prevent us from acting for another client on that matter without further consent from you. However, as required, we shall put in place such arrangements as we consider appropriate in the circumstances to ensure that the confidentiality of your documents and/or information is maintained.
            </p>
           

            <h2 className="text-xl font-semibold mb-2">7. Instructing Other Experts and Lawyers in Other Jurisdictions</h2>
            <p className="mb-4">
            It may be necessary during the course of a matter to instruct one or more experts outside the Firm, such as accountants, consultants or specialist or foreign counsel. We shall discuss this with you at the appropriate time should this arise, so as to agree to retaining a suitable entity and the costs likely to be involved.
            </p>


            <h2 className="text-xl font-semibold mb-2">8. Documents</h2>
            <p className="mb-4">
            8.1 Copyright: Original material which we generate for our clients’ purposes are protected by copyright, which belongs to Ali Sharif Zu’bi Advocates & Legal Consultants.
            </p>

            <p className="mb-4">
            8.2 Retention: We shall retain files relating to a matter on which we represented you for ten (10) years after the matter’s completion. Unless agreed otherwise we shall then dispose of such files without further reference to you.
            </p>


            <h2 className="text-xl font-semibold mb-2">9. Resolving Problems and Disputes</h2>
            <p className="mb-4">
            9.1 In the event of a disagreement or complaint that you might have regarding our services, please contact the partner in charge of your account and provide an explanation of the disagreement or complaint in writing. If you do not receive a reply from us within seven (7) days, kindly contact our Managing Partner, Khaled Asfour, directly.
            </p>

            <p className="mb-4">
            9.2 Should an amicable solution not be reached, the dispute will be referred to arbitration in accordance with the current Jordanian Arbitration Law.
            </p>

          </div>
        </div>
      </div>
     
    </>
  );
};

export default TermsAndConditions;
