// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import Slider from 'react-slick';
// import { useNavigate } from 'react-router-dom';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Slider1 from '../Components/SliderDepartments'; // Ensure the correct path to the slider component

// const styles = `
//   @keyframes scaleUp {
//     from {
//       transform: scale(1);
//     }
//     to {
//       transform: scale(1.05);
//     }
//   }

//   .hover-card {
//     transition: transform 0.3s ease;
//   }

//   .hover-card:hover {
//     animation: scaleUp 0.3s ease forwards;
//   }
// `;
// const Card = ({ image, headtitle, title, onClick }) => (
//   <div 
//     className="hover-card border rounded-lg overflow-hidden relative mb-8 mt-8 w-72 md:w-64 xl:w-40 cursor-pointer mx-auto pb-10 h-96 flex flex-col"
//     onClick={onClick}
//   >
//     <img src={image} alt={headtitle} className="w-full object-contain rounded-t-lg" />
//     <div className="p-4 flex flex-col justify-between flex-grow">
//       <h1 className="font-bold text-md mt-4 mb-2 text-center md:text-md" style={{ fontFamily: 'Arial' }}>{headtitle}</h1>
//       <h2 className="text-sm mt-1 mb-4 text-center md:text-md" style={{ fontFamily: 'Arial' }}>{title}</h2>
//     </div>
//   </div>
// );



// const Departments = () => {
//   const [departmentsData, setDepartmentsData] = useState([]);
//   const [images, setImages] = useState([]);
//   const navigate = useNavigate();
//   const sliderRefs = useRef([]);

//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 2100,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const nextSlide = (index) => {
//     sliderRefs.current[index].slickNext();
//   };

//   const prevSlide = (index) => {
//     sliderRefs.current[index].slickPrev();
//   };

//   useEffect(() => {
//     const fetchSliderDetails = async () => {
//       try {
//         const response = await axios.get('https://demo1.we-demo.xyz/slider/list');
//         if (response.data && response.data.data && response.data.data.length > 0) {
//           const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
//           setImages(sliderImages);
//         } else {
//           console.error('Unexpected response structure or no slider details:', response.data);
//         }
//       } catch (error) {
//         console.error('Failed to fetch slider details', error);
//       }
//     };
  
//     fetchSliderDetails();
//   }, []);

//   useEffect(() => {
//     const fetchDepartmentsData = async () => {
//       try {
//         const response = await axios.get('https://demo1.we-demo.xyz/DepartmentsRoutes/list');
//         setDepartmentsData(response.data.data);
//       } catch (error) {
//         console.error("Failed to fetch departments data:", error);
//       }
//     };

//     fetchDepartmentsData();
//   }, []);

//   const handleCardClick = (item) => {
//     navigate('/Departments-Detailed', {
//       state: {
//         image: `https://demo1.we-demo.xyz/${item.image}`,
//         headtitle: item.headtitle,
//         title: item.title,
//         paragraph: item.paragraph,
//         content: item.content,
//         lastUpdate: item.lastUpdate, // Add the last update field
//       }
//     });
//   };

//   const groupedByDepartment = departmentsData.reduce((acc, item) => {
//     const department = item.department || 'Unknown Department';
//     if (!acc[department]) {
//       acc[department] = [];
//     }
//     acc[department].push(item);
//     return acc;
//   }, {});

//   return (
//     <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 mb-20 pb-20" style={{ backgroundColor: 'white' }}>
//      <Slider1 images={images} Departments="Of Counsel"/>
//       <h1 className="text-2xl md:text-4xl font-bold text-center mb-10 pt-20 text-[#972a36]" style={{ fontFamily: 'Arial' }}>Meet our of counsel</h1>
//       <style>{styles}</style>
//       {Object.keys(groupedByDepartment).map((department, index) => (
//         <div key={index} className="w-full">
//           {/* <h2 className="text-lg md:text-2xl font-bold text-center mb-6 mt-14 " style={{ fontFamily: 'Arial' }}>{department}</h2> */}
//           <div className="relative ">
//             <Slider ref={(el) => (sliderRefs.current[index] = el)} {...settings} >
//               {groupedByDepartment[department].map((item, itemIndex) => (
//                 <div key={itemIndex} className="flex justify-center items-center w-full ">
//                   <Card 
//                     image={`https://demo1.we-demo.xyz/${item.image}`}
//                     headtitle={item.headtitle}
//                     title={item.title}
//                     onClick={() => handleCardClick(item)}
//                   />
//                 </div>
//               ))}
//             </Slider>
//             <button
//               onClick={() => nextSlide(index)}
//               className="absolute right-2 sm:right-4 md:right-7 lg:right-10 xl:right-12 text-xl md:text-3xl lg:text-xl xl:text-4xl z-10"
//               style={{
//                 top: '50%',
//                 transform: 'translateY(-50%)',
//                 color: '#ffffff',
//                 textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)',
//               }}
//             >
//               &#10095;
//             </button>
//             <button
//               onClick={() => prevSlide(index)}
//               className="absolute left-2 sm:left-4 md:left-7 lg:left-10 xl:left-12 text-xl md:text-3xl lg:text-xl xl:text-4xl z-10"
//               style={{
//                 top: '50%',
//                 transform: 'translateY(-50%)',
//                 color: '#ffffff',
//                 textShadow: '0px 0px 8px rgba(0, 0, 0, 0.8)',
//               }}
//             >
//               &#10094;
//             </button>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default Departments;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Slider1 from '../Components/SliderLawyers'; // Ensure the correct path to the slider component

const renderParagraph = (para) => {
  // Remove the surrounding <ul></ul> tags
  const cleanedContent = para.replace(/<\/?ul[^>]*>/g, '');
  // Split the cleaned content into individual items
  const items = cleanedContent.split('</li>').map((item, index) => {
    // Remove opening <li> tag and any extra spaces
    const cleanedItem = item.replace('<li>', '').trim();
    // Render each item as a div with bullet point if not empty
    return cleanedItem ? <div key={index} className="mb-2">• {cleanedItem}</div> : null;
  });

  // Return the list of items wrapped in a div
  return <div className="mb-4 text-left ml-5 mt-5">{items}</div>;
};


const enhanceHtml = (html) => {
  return html
    .replace(/<h1>/g, '<h1 class="text-4xl font-bold">')
    .replace(/<li>/g, '<li class="list-disc list-inside">');
    
};

const Card = ({ image, headtitle, title, paragraph, onClick }) => (
  <div 
    className="border rounded-lg overflow-hidden relative mb-8 w-full cursor-pointer flex"
    onClick={onClick}
  >
    <div className="flex-shrink-0">
      <img src={image} alt={headtitle} className="w-40 h-46 object-cover m-4" />
    </div>
    <div className="p-4 flex flex-col justify-between flex-grow">
      <h1 className="font-bold text-lg mb-1" style={{ fontFamily: 'Arial' }}>{headtitle}</h1>
      <h2 className="text-sm mb-1" style={{ fontFamily: 'Arial' }}>{title}</h2>
      <p className="text-sm mb-1" dangerouslySetInnerHTML={{ __html: enhanceHtml(paragraph) }}></p>
      <div className="text-right">
      <button className="text-[#972a36] text-sm">See my full profile</button>
      </div>
    </div>
  </div>
);


const Departments = () => {
  const [departmentsData, setDepartmentsData] = useState([]);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    const fetchTexts = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/SliderDepartment/listTexts');
        setTexts(response.data.data || []);
      } catch (error) {
        console.error('Failed to fetch texts', error);
      }
    };
  
    fetchTexts();
  }, []);
  

  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };
  
    fetchSliderDetails();
  }, []);

  useEffect(() => {
    const fetchDepartmentsData = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/DepartmentsRoutes/list');
        setDepartmentsData(response.data.data);
      } catch (error) {
        console.error("Failed to fetch departments data:", error);
      }
    };

    fetchDepartmentsData();
  }, []);

  const handleCardClick = (item) => {
    navigate('/Departments-Detailed', {
      state: {
        image: `https://demo1.we-demo.xyz/${item.image}`,
        headtitle: item.headtitle,
        title: item.title,
        contact: item.contact,
        phone: item.phone,
        email: item.email,
        paragraph: item.paragraph || 'No description available', // Provide a default value
        content: item.content,
        lastUpdate: item.lastUpdate,
      }
    });
  };
  
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const groupedByDepartment = departmentsData.reduce((acc, item) => {
    const department = item.department || 'Unknown Department';
    if (!acc[department]) {
      acc[department] = [];
    }
    acc[department].push(item);
    return acc;
  }, {});

  return (
    <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-20" style={{ backgroundColor: 'white' }}>
      <Slider1 images={images} Departments="Of Counsel"/>
      <div className="w-full mt-20">
      {texts.map((text, index) => (
        <h2 className="text-3xl font-semibold mb-2 text-[#972a36] ml-7" key={index} dangerouslySetInnerHTML={{ __html: text.text }}></h2>
))}
 <div className="bg-[#777777] h-0.5 w-16  mt-2 mb-6 ml-7" ></div>
 </div>

      {Object.keys(groupedByDepartment).map((department, index) => (
        <div key={index} className="w-full mt-10">



          {groupedByDepartment[department].map((item, itemIndex) => (
            <div key={itemIndex} className="w-full">
      <Card 
  image={`https://demo1.we-demo.xyz/${item.image}`}
  headtitle={item.headtitle}
  title={item.title}
  paragraph={item.paragraph}  // Ensure paragraph is passed here
  onClick={() => handleCardClick(item)}
/>

            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Departments;
