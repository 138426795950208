import React from 'react';
import Slider from '../Components/Slider';



const ConfidentialityAndPrivacy = () => {
  return (
    <>
      
      <div className="container bg-[#777777] items-center   mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0 pb-10 mb-20" style={{ backgroundColor: 'white' }}>
      <Slider />
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-14">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-6">
          <h1 className="text-4xl font-bold text-center mb-12 mt-5">Confidentiality and Privacy Information</h1>
           
            <p className="mb-14">
            As we are committed to protecting any personal information we receive, Ali Sharif Zu’bi Advocates & Legal Consultants (the “Firm”) takes all reasonable measures in ensuring the privacy, confidentiality and security of personal information by maintaining high standards in security systems, restricted file access, technology security with a private server located inside the Firm, internal passwords and security policies. The Firm is committed to maintaining the highest standards of privacy and confidentiality.
            </p>

            <p className="mb-14">
            The Firm collects and uses personal information primarily to provide legal services, including but not limited to, establishing and managing lawyer-client relationships, avoiding conflicts of interest, developing and managing the Firm’s knowledge-management precedent systems and databases, detecting error, negligence, breach of contract, fraud, theft and other illegal activity and protecting the Firm from the same, auditing compliance with the Firm’s policies and contractual obligations, for any purpose required to comply with any legal or regulatory requirements or provisions and for any other purposes for which your consent has been obtained.
            </p>

            <p className="mb-14">
            The types of personal information the Firm may collect include name, address, billing and any other information relevant to a legal matter(s) incidental to the provision of legal advice and services by the Firm, such as personal information about directors, officers and employees or employers of a client, decision makers, experts, business partners, clients, other party, witnesses, beneficiaries, family members, adverse parties or parties-in-interest, shareholders, investors other professional advisors, investigators, etc. Your consent for the collection, use and disclosure of your personal information can be accepted orally or in writing, depending on the circumstances and in some cases may be implied through your conduct. The Firm collects information only by lawful and fair means and not in an unreasonably intrusive way. Wherever possible, the Firm obtains personal information directly from you prior to and throughout the course of representation. At times the Firm may also obtain information from other sources, including Google, Yahoo, Bing, LinkedIn, Facebook, Twitter, Corporate Searches etc. The Firm will re-obtain your consent if we intend to use your personal information for a purpose that varies from the initial reason it was obtained.
            </p>
            

            <p className="mb-14">
            The Firm will disclose your personal information under certain circumstances. These circumstances include but are not limited to: when required or authorized to do so by law; when your consent for the disclosure has been obtained; when disclosure is required for the delivery of legal services wherein your consent is implied; where it is necessary to establish or collect fees; if the Firm engages a third party, bound by this policy, to provide administrative services to us; if the Firm engages expert witnesses on your behalf; if the Firm retains other law firms in this or other jurisdictions on your behalf; or, if the information is already publicly known. Exceptions to the provision of access to personal information include but are not limited to: information protected by solicitor-client privilege; information generated in the course of a formal dispute resolution process; information about another individual where disclosure would reveal confidential commercial information. The Firm does not disclose your personal information to any third party to assist the third party in marketing their products or services and does not provide our client mailing lists to other parties.
            </p>

            <p className="mb-14">
            As accuracy of information is critical in providing legal services, it is important that if your personal information changes, that you immediately inform the assigned lawyer in writing of such changes so that all necessary updates can be made promptly. When contacting the Firm using e-mail, please be aware that it is not a 100% secure medium to use for sending confidential and personal information, despite numerous steps taken by the Firm to secure it.
            </p>

            <p className="mb-14">
            The Firm regularly reviews all of its policies and procedures and reserves the right to make changes to the Confidentiality and Privacy of Information Policy at any time.
            </p>

            <p className="mb-14">
            This policy is governed by and interpreted in accordance with the laws of the Hashemite Kingdom of Jordan applicable therein, and does not create or confer upon any individual any rights, or impose upon the Firm any rights or obligations outside of, or in addition to, any rights or obligations imposed by the applicable laws. In the event of a conflict between any term of this policy and the applicable laws, the applicable laws shall prevail.
            </p>

            <p className="mb-7">
            If you would like more information, or have a specific question you would like to discuss with the Firm, please contact our managing partner at <a href="mailto:contact@zubipartners.com" style={{ textDecoration: 'none', color: '#840626', fontWeight: 'bold' }}> thaer@law-amro.com </a>
            </p>

          </div>
        </div>
      </div>
     
    </>
  );
};

export default ConfidentialityAndPrivacy;
