import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './Components/NavBar'; 
import Footer from './Components/Footer'; 
import Home from './Pages/Home'; 
import Lawyers from './Pages/Lawyers';
import DetailedLawyersPage from './Pages/DetailedLawyersPage'; 
import Departments  from './Pages/Departments'; 
import Associates  from './Pages/Associates'; 
import DetailedView from './Pages/DetailedDepartmentsPage';
import DetailedAssociates from './Pages/DetailedAssociatesPage';
import ContactUs from './Pages/ContactUs';
import Profile from './Pages/Profile';
import OurFounder from './Pages/OurFounder';
import WhatTheySay from './Pages/WhatTheySay';
import Publications from './Pages/Publications';
import Awards from './Pages/Awards';
import AreasOfPractice from './Pages/AreasOfPractice';
import News from './Pages/News';
import NewsDetail from './Pages/NewsDetail';
import AssociatedOffices from './Pages/AssociatedOffices';
import TermsAndConditions from './Pages/TermsAndConditions';
import ConfidentialityAndPrivacy from './Pages/ConfidentialityAndPrivacy';
import Disclaimer from './Pages/Disclaimer';
import NewPageForm from './Components/NewPageNavbar';
import PracticeDetail from './Pages/PracticeDetail';

// import NewPageFooter from './Components/NewPageFooter';
import axios from 'axios';


import { useMediaQuery } from '@mui/material';



function App() {
  const [dynamicRoutes, setDynamicRoutes] = useState([]);
  // const [dynamicRoutesFooter, setDynamicRoutesFooter] = useState([]);
  const isXlScreen = useMediaQuery('(min-width:1280px)');

  useEffect(() => {
    const fetchDynamicRoutes = async () => {
      try {
        // Replace 'https://zubiback.qtechnetworks.co.uk/navbar/list' with the appropriate URL for your backend
        const response = await axios.get('https://zubiback.qtechnetworks.co.uk/navbar/${link}');
        setDynamicRoutes(response.data.data); // Assuming the response has a data property that contains the routes
      } catch (error) {
        console.error(`Error fetching dynamic routes: ${error}`);
      }
    };

    fetchDynamicRoutes();
  }, []);



;




  return (
    <Router>
    <div style={{
      backgroundColor: '#f5f5f5',
      border: isXlScreen ? '3px solid #7b7b7b' : 'none',
    }}>
      <NavBar />
      <Routes>
    
          <Route path="/" element={<Home />} />
          <Route path="/:link" element={<NewPageForm />} />
          <Route path="/Lawyers" element={<Lawyers />} />
          <Route path="/Lawyers-details" element={<DetailedLawyersPage />} />
          <Route path="/PracticeDetail/:id" element={<PracticeDetail />} />
          <Route path="/Departments" element={<Departments  />} />
          <Route path="/Departments-Detailed" element={<DetailedView />} />
          <Route path="/Associates" element={<Associates  />} />
          <Route path="/Detailed-Associates" element={<DetailedAssociates />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us/profile" element={<Profile />} />
          <Route path="/about-us/our-founder" element={<OurFounder />} />
          <Route path="/about-us/what-they-say" element={<WhatTheySay />} />
          <Route path="/about-us/Publications" element={<Publications />} />
          <Route path="/about-us/Awards" element={<Awards />} />
          <Route path="/areas-of-practice" element={<AreasOfPractice />} />
          <Route path="/News" element={<News />} />
          <Route path="/news/:title" element={<NewsDetail />} />
          <Route path="/Associated_Offices" element={<AssociatedOffices />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<ConfidentialityAndPrivacy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          {dynamicRoutes.map((route) => (
            <Route key={route.id} path={route.link} element={<NewPageForm contentId={route.id} />} />
          ))}
          
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
