import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Slider from '../Components/SliderAreasOfPractice';

// PracticeCard Component
const PracticeCard = ({ detail }) => {
  // Define keyframes and styles for hover animation
  const styles = `
    @keyframes scaleUp {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.05);
      }
    }

    .hover-card {
      transition: transform 0.3s ease;
    }

    .hover-card:hover {
      animation: scaleUp 0.3s ease forwards;
    }
  `;

  // Inject keyframes into the document
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }, []);

  return (
    <Link to={`/PracticeDetail/${detail.id}`} state={{ detail }} className="hover-card border rounded-lg shadow-lg overflow-hidden relative m-4 w-full sm:w-72 lg:w-72">
      <div className="h-full overflow-hidden relative flex flex-col">
        <img src={`https://demo1.we-demo.xyz/${detail.image}`} alt={detail.title} className="w-full h-60 object-cover" />
        <p className="text-md text-center bg-[#972a36] w-full flex items-center justify-center text-white" style={{ fontFamily: 'Arial', height: '60px' }}>{detail.title}</p>
      </div>
    </Link>
  );
};

// FieldsOfPractice Component
const FieldsOfPractice = () => {
  const [practiceItems, setPracticeItems] = useState([]);
  const [practiceDetails, setPracticeDetails] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchSliderDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/slider/list');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const sliderImages = response.data.data.map(slider => slider.slider || 'fallback-image-path.jpg');
          setImages(sliderImages);
        } else {
          console.error('Unexpected response structure or no slider details:', response.data);
        }
      } catch (error) {
        console.error('Failed to fetch slider details', error);
      }
    };

    fetchSliderDetails();
  }, []);

  useEffect(() => {
    const fetchPracticeItems = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/practice/list');
        setPracticeItems(response.data.data);
      } catch (error) {
        console.error("Error fetching practice items:", error);
      }
    };

    fetchPracticeItems();
  }, []);

  useEffect(() => {
    const fetchPracticeDetails = async () => {
      try {
        const response = await axios.get('https://demo1.we-demo.xyz/practicedetails/list');
        setPracticeDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching practice details:", error);
      }
    };

    fetchPracticeDetails();
  }, []);

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container bg-[#777777] items-center mx-auto xl:max-w-7xl w-full px-5 md:px-0 xl:px-0  pb-20" style={{ backgroundColor: 'white' }}>
      <Slider AreasOfPractice="Fields of Practice"/>
      {practiceItems.length > 0 && (
        <>
          <h2 className="text-md md:text-2xl font-bold text-center mt-20 mb-8 text-[#972a36]" style={{ fontFamily: 'Arial' }}>{practiceItems[0].title}</h2>
          <div className="flex flex-col items-center justify-center py-10">
  {practiceItems[0].paragraph.split('\n').map((para, index) => (
    <p
      key={index}
      className={`max-w-5xl text-start mb-2 px-3 md:px-6 xl:px-4 ${index === practiceItems[0].paragraph.split('\n').length - 1 ? 'mb-0' : ''}`}
      style={{ textAlign: 'justify', textJustify: 'inter-word', fontFamily: 'Arial' }}
    >
      {para}
    </p>
  ))}
</div>

        </>
      )}
      <div className="w-full border-t border-gray-300 mb-5 mx-auto" style={{ maxWidth: '50%' }}></div>
      <div className="max-w-5xl mx-auto px-0 xl:px-14 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-28 gap-y-8 justify-items-center">
        {practiceDetails.map((detail, index) => (
          <PracticeCard key={index} detail={detail} />
        ))}
      </div>
    </div>
  );
};

export default FieldsOfPractice;
